import overrides from './overrides';
import styles from './styles';
import config from './config';
import { getNavigation } from './navigation';

const configuration = {
    overrides,
    styles,
    config,
    getNavigation,
}

export default configuration;
