import React from 'react';

export default class PDLCardPreview extends React.Component {
  _getImage(card) {
    let image = null;
    if (card.category === 'standard') {
      image = require('../../../assets/variants/tillo/card_with_logo.png');
    }

    if (card.status === 'inactive' || card.status === 'pending_activation') {
      image = require('../../../assets/img/activate_card.png');
    }

    return image;
  }

  render() {
    const { card } = this.props;
    const { pan } = card;
    const formatPan = pan.match(/.{1,4}/g);
    const newFormatPan = `${formatPan[0]} ${formatPan[1]} ${formatPan[2]} ${formatPan[3]}`;

    console.log(card);

    return (
      <div style={{ position: 'relative', maxWidth: 500 }}>
        <img
          src={this._getImage(this.props.card)}
          style={{ width: '100%', height: 'auto', borderRadius: 5 }}
          alt="card"
        />

        {card.status === 'active' || 'stolen' || 'locked' ? (
          <span
            style={{
              position: 'absolute',
              fontSize: 20,
              left: '10%',
              bottom: '45%'
            }}
          >
            {newFormatPan}
          </span>
        ) : null}
        {card.status === 'active' || 'stolen' || 'locked' ? (
          <span
            style={{
              position: 'absolute',
              fontSize: 18,
              left: '10%',
              bottom: '26%'
            }}
          >
            {card.expires}
          </span>
        ) : null}
        {card.status === 'active' || 'stolen' || 'locked' ? (
          <span
            style={{
              position: 'absolute',
              fontSize: 18,
              left: '10%',
              bottom: '10%'
            }}
          >
            {card.emboss}
          </span>
        ) : null}
      </div>
    );
  }
}
