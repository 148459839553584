import React from 'react';
import SwitchButton from '../../../../@launchpad/components/crud/SwitchButton';
import Api from '../../../../@launchpad/logic/api/Api';
import LoadingOverlay from '../../../../@launchpad/components/crud/LoadingOverlay';
import Auth from '../../../../@launchpad/logic/services/AuthService';

class CardNotifications extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleNotifications(id: any, status: any) {
    this.setState({
      loading: true
    });

    this.changeStatusNotifications(id, status)
      .then(() => {
        this.setState({
          loading: false
        });
        Auth.refreshAuth();
      })
      .catch(() => {});
  }

  async changeStatusNotifications(id: any, status: any) {
    const response = await Api.call('payment-device/notifications/configure', {
      id,
      enabled: !!status
    });

    return response;
  }

  render() {
    const { card } = this.props;

    console.log('aaaa', card);

    return (
      <>
        <div className="d-flex flex-row align-items-center justify-content-between my-3">
          Notifications
          <SwitchButton
            isChecked={!!card.notificationsEnabled}
            onChange={(val: any) => this.handleNotifications(card.id, val)}
          />
        </div>
      </>
    );
  }
}

export default CardNotifications;
