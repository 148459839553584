import React from 'react';
import LaunchPadAdmin from './../@launchpad/LaunchPadAdmin';
import configuration from './configuration';

export default class PDLAdmin extends React.Component<any, any> {

  render() {
    return <LaunchPadAdmin configuration={configuration} />;
  }
}
