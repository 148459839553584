export const getNavigation = (appNavigation: any) => {

  let navigation = appNavigation.filter(x => x.slug != 'push-notifications' && x.slug != 'kyc');

  navigation = navigation.filter(x => x.label != 'Analytics');
  navigation = navigation.filter(x => x.label != 'FX Rates');
  navigation = navigation.filter(x => x.label != 'Notifications');

  let contentManagement = navigation.find(x => x.slug == 'content-management');

  let reports = navigation.find(x => x.label == 'Reports');

  // if(reports) {
  //   reports.children = reports.children.filter(x => x.label != 'Totals');
  // }


  let users = navigation.find(x => x.label == 'Users');
  if(users) {
    users.children = users.children.filter(x => (x.label == 'All Users' || x.label == ' User Devices'));
  }

  let children = contentManagement.children.filter(x => x.slug != 'faq');
  contentManagement.children = children;

  return navigation;
}

export default {
  getNavigation
}
