import React from 'react';
import HomeWigets from './components/HomeWidgets';



const home = {
  widgets: {
    // top: () => <HomeWigets/>,
    // bottom: () => null
  }
}

export default home;
